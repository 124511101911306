<template>
  <!-- eslint-disable  -->
  <div>
    <router-view
      v-if="$route.name === '子通道清單' || $route.name === '子帳號清單'"
    />

    <div v-if="$route.name === '通道帳務統計'">
      <v-card flat class="w100p">
        <!--  -->
      </v-card>
      <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
        通道帳務統計
        <v-spacer></v-spacer>
      </div>
      <v-card
        outlined
        :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
      >
        <v-card-text class="d-none d-sm-flex align-center">
          <v-spacer></v-spacer>
          <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

          <SizeBox width="14" />
          <ExportBtn
            :items="result.list"
            :headers="headers"
            :file-name="`${
              $store.getters['app/getRouteTitle']
            } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
            sheet-name="sheetName"
            :disabled="!$getGrantValue('channel_accounting_reports.export')"
            :exclude="['sub_channel']"
          />
        </v-card-text>
        <DataTable
          :loading="isLoading"
          :list="result.list"
          :total="result.total"
          :fields="headers"
          :replace="['channel_type', 'sub_channel']"
          @get-list="fetchData"
        >
          <template #item.channel_type="{ item }">
            <v-chip :color="parseTypeColor(item.channel_type)" small>
              {{ item.channel_type }}
            </v-chip>
          </template>

          <template #item.sub_channel="{ item }">
            <div class="flex-center">
              <v-btn
                rounded
                @click="handleAction((vm) => item.sub_channel.action(vm, item))"
                color="Secondary100"
                class="mx-2 my-3"
                depressed
                small
                :to="item.sub_channel.to ? item.sub_channel.to(item) : null"
              >
                <v-icon v-if="!!item.sub_channel.icon" size="18" class="mr-1">
                  {{ item.sub_channel.icon }}
                </v-icon>
                {{ item.sub_channel.label }}
              </v-btn>
            </div>
          </template>
        </DataTable>
      </v-card>
    </div>
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>

<script>
import { getChannelAccountingReports } from '@/api/statistics';

const sub_channel = {
  label: '子通道清單',
  icon: 'mdi-transit-detour',
  action: (vm, v) => {
    // vm.openForm('Detail', v);
  },
  to: (v) =>
    v.channel_type_id
      ? {
          name: '子通道清單',
          params: { channel_type_id: v.channel_type_id }
        }
      : null
};

export default {
  data() {
    return {
      ...this.$clone(this.$commonSchema),
      headers: [
        {
          text: '通道類型',
          value: 'channel_type',
          align: 'center',
          width: '104px'
        },
        {
          text: '子通道',
          value: 'sub_channel',
          align: 'center',
          width: '104px'
        },
        {
          text: '提單總額',
          value: 'total_collect_order_amount',
          align: 'center',
          width: '104px'
        },
        {
          text: '代收手續費',
          value: 'total_collect_order_fee',
          align: 'center',
          width: '104px'
        },
        {
          text: '總用戶入帳',
          value: 'total_paid_collect_order_credit_amt',
          align: 'center',
          width: '104px'
        },
        {
          text: '代付總額',
          value: 'total_pay_order_amount',
          align: 'center',
          width: '104px'
        },
        {
          text: '代付手續費',
          value: 'total_pay_order_fee',
          align: 'center',
          width: '104px'
        },
        {
          text: '到帳金額',
          value: 'total_pay_order_actual_amt',
          align: 'center',
          width: '104px'
        },
        {
          text: '交易筆數',
          value: 'total_order',
          align: 'center',
          width: '104px'
        },
        {
          text: '交易成功率',
          value: 'success_rate',
          align: 'center',
          width: '104px'
        },
        {
          text: '成功筆數',
          value: 'success_count',
          align: 'center',
          width: '104px'
        },
        {
          text: '失敗筆數',
          value: 'failure_count',
          align: 'center',
          width: '104px'
        }
      ]
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;

      const Data = await getChannelAccountingReports({
        ...this.filter,
        ...condition
      });
      if (!Data.error) {
        this.result.total = Data.total;
        this.result.page = Data.page;
        this.$set(
          this.result,
          'list',
          Data.items.map((item) => ({
            ...item,
            success_rate:
              Number(this.$num.multiply(item.success_rate, 100)).toFixed(2) +
              '%',
            sub_channel
          }))
        );
      }

      this.isLoading = false;
    },
    handleAction(fn) {
      fn(this);
    },
    parseTypeColor(channel_type_code) {
      switch (channel_type_code) {
        case '銀行轉帳':
          return 'Alert300';
        case '網觀轉帳':
          return 'Secondary100';

        default:
          return 'Secondary100';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
